import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  DollarDetailPage,
  DollarPage,
  GoldDetailPage,
  GoldPage,
  SilverDetailPage,
  SilverPage,
  News,
} from "./Components";

import Layout from "./Components/Layout/Layout";

import HomePage from "./Page/HomePage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Layout}>
          <Route path="/" Component={HomePage} />
          <Route path="/altin">
            <Route index Component={GoldPage} />
            <Route path=":id" Component={GoldDetailPage} />
          </Route>
          <Route path="/gumus">
            <Route index Component={SilverPage} />
            <Route path=":id" Component={SilverDetailPage} />
          </Route>
          <Route path="/dolar">
            <Route index Component={DollarPage} />
            <Route path=":id" Component={DollarDetailPage} />
          </Route>
          <Route path="/haber/:id" Component={News} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
