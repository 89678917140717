import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PriceCard from "../PriceCard/PriceCard";

const Navbar = () => {
  const [prices, setPrices] = useState([
    {
      href: "dolar",
      title: "DOLAR",
      price: 32.7525,
      percentageChange: -0.25,
      valueChange: -0.0821,
    },
    {
      href: "altin",
      title: "GRAM ALTIN",
      price: 2450.67,
      percentageChange: 0.29,
      valueChange: 7.13,
    },
    {
      href: "altin",
      title: "Çeyrek Altın",
      price: 41.5051,
      percentageChange: -0.01,
      valueChange: -0.0042,
    },
    {
      href: "altin",
      title: "Yarım Altın",
      price: 10647.91,
      percentageChange: -0.31,
      valueChange: -33.11,
    },
    {
      href: "gumus",
      title: "Gram Gümüş",
      price: 35.0851,
      percentageChange: -0.54,
      valueChange: -0.1905,
    },
  ]);

  useEffect(() => {
    // fetchPrices();
  }, []);

  // const fetchPrices = async () => {
  //   const response = await fetch("FİYATLAR_API_URL");
  //   const data = await response.json();
  //   setPrices({
  //     goldPrice: data.goldPrice,
  //     silverPrice: data.silverPrice,
  //     dollarPrice: data.dollarPrice,
  //   });
  // };

  return (
    <div className="w-full sticky top-0 left-0 z-50">
      <nav className="bg-blue-800">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="text-white text-lg font-bold">
            LastPriceTag
          </Link>
          <div className="flex space-x-4 text-white">
            <Link
              to="/altin"
              className=" hover:bg-red-200 hover:text-black px-1 py-2 duration-300"
            >
              Altın
            </Link>
            <Link
              to="/gumus"
              className=" hover:bg-red-200 hover:text-black px-1 py-2 duration-300"
            >
              Gümüş
            </Link>
            <Link
              to="/dolar"
              className=" hover:bg-red-200 hover:text-black px-1 py-2 duration-300"
            >
              Dolar
            </Link>
          </div>
        </div>
      </nav>
      <header className="bg-blue-900 px-4 py-2 text-white">
        <div className="container mx-auto flex gap-5 md:gap-2 justify-between items-center overflow-x-auto">
          {prices.map((item, index) => (
            <PriceCard
              key={index}
              title={item.title}
              price={item.price.toFixed(2)}
              percentageChange={item.percentageChange.toFixed(2)}
              valueChange={item.valueChange.toFixed(2)}
              href={item.href}
            />
          ))}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
