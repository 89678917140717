import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  const news = [
    {
      id: 1,
      baslik: "Ekonomik Durağanlık ve Altın Yatırımı İçin Fırsatlar",
      aciklama:
        "Son dönemde Türkiye ekonomisinde dikkat çeken bir durum yaşanıyor. Dolar/TL kuru yaklaşık 3-4 aydır 32.70 TL civarında seyrediyor. Küresel piyasalarda ise ons altın fiyatı 2.300 dolar civarında baskılanıyor. Bu durum, özellikle orta ve uzun vadeli yatırımlar için cazip bir fırsat sunuyor. Ancak bu fırsatlar, kredi kartı ve eksi bakiye gibi kredi borcu olmayanlar için geçerli.",
    },
    {
      id: 2,
      baslik: " Türkiye ve Dünyada Altın Fiyatları nasıl belirlenir ?",
      aciklama:
        "Türkiye ve dünyada altın fiyatları, uluslararası piyasalardaki arz ve talep dinamiklerine, ekonomik göstergelere, jeopolitik gelişmelere ve merkez bankalarının politikalarına bağlı olarak belirlenir. İşte altın fiyatlarının hesaplanmasında dikkate alınan temel unsurlar:",
    },
    {
      id: 3,
      baslik:
        " 2.5 Gram Altının Fiyatından 1 Gram Altının Fiyatını Nasıl Hesaplarız?",
      aciklama:
        "Altın fiyatları yatırımcılar için önemli bir bilgi kaynağıdır. 2.5 gram altının fiyatları şu an 6.602,34 TL. Peki, bu fiyatı 1 gram bazında nasıl hesaplayabilirsiniz? Bu yazımızda, herkesin kolayca anlayabileceği bir şekilde, 2.5 gram altının fiyatından 1 gram altının fiyatını hesaplamayı açıklayacağız.",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex flex-col justify-evenly">
          {" "}
          <p className="text-lg">
            <span className="font-medium">Yatırımda Yeni Dönem:</span>Uygun
            Fiyata Yatırım Yapmanın Kolay Yolu!
          </p>
          <div>
            {" "}
            <h1 className="font-bold">Neden Biz?</h1>
            <p className="">
              Altın, yıllardır güvenli liman olarak bilinir ve yatırımcıların
              tercih ettiği bir varlık olarak öne çıkar. Ancak, altın
              fiyatlarının sürekli değişkenlik göstermesi, yatırımcıları en
              uygun fiyatı bulma konusunda zorlayabiliyor. İşte bu noktada,
              sizin için devrim niteliğinde bir çözüm sunuyoruz.{" "}
              <Link
                to="/haber/neden-biz-lastpricetag.com"
                className="text-blue-500 hover:text-blue-900 duration-300"
              >
                Detaylı bilgi
              </Link>
            </p>
          </div>
        </div>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/eZmk6x_q16E"
          title="Promotional Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div>
        {" "}
        <h1 className="text-2xl font-bold my-3">Blog</h1>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-3">
          {news.map((haber, index) => (
            <Link
              to={`/haber/${haber.baslik.replace(/\s+/g, "-").toLowerCase()}`}
              key={haber.id}
              className={`text-blue-800 group pt-1 ${
                index > 1 ? "border-t-[2px]" : "border-none"
              }`}
            >
              {" "}
              <h2 className="text-lg md:text-xl font-bold group-hover:underline duration-300">
                {haber.baslik}
              </h2>
              <p className="text-gray-700 line-clamp-2">{haber.aciklama}</p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
