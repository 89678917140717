import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-blue-800 text-white py-2">
      {" "}
      <div className="container mx-auto flex flex-col gap-5 md:flex-row justify-between items-center">
        <div className="">
          <h2 className="text-xl font-bold text-center">LastPriceTag</h2>
          <p className="mt-2">Uygun Fiyata Yatırım Yapmanın Kolay Yolu</p>
        </div>
        <button onClick={() => alert("Desteğiniz İçin Teşekkürler")}>
          Kahve Ismarlamak İçin
        </button>
        <nav className="flex space-x-4">
          <Link
            target="_blank"
            to="https://x.com/lastpricetag"
            className="hover:text-gray-400"
            rel="noopener noreferrer"
          >
            Twitter
          </Link>
          <Link
            target="_blank"
            to="https://www.youtube.com/@LastPriceTag"
            className="hover:text-gray-400"
            rel="noopener noreferrer"
          >
            Youtube
          </Link>
          <Link
            target="_blank"
            to="https://www.instagram.com/lastpricetag/"
            className="hover:text-gray-400"
            rel="noopener noreferrer"
          >
            İnstagram
          </Link>
          <Link
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSei_tILXsT5k1QKa8xzmeFF4-q6Axy0EDM3aW5YRHS6bXADtg/viewform"
            className="hover:text-gray-400"
            rel="noopener noreferrer"
          >
            Bize Ulaşın
          </Link>
        </nav>
      </div>
      <div className="mt-4 text-center text-gray-100">
        <p>&copy; 20024 Her Hakkı Saklıdır.</p>
      </div>
    </footer>
  );
};

export default Footer;
