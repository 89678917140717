import React from "react";
import { Link } from "react-router-dom";

const PriceCard = ({ title, price, percentageChange, valueChange, href }) => {
  const isNegative = percentageChange < 0;
  const percentageClass = isNegative ? "text-red-500" : "text-green-500";
  const valueClass = isNegative
    ? "bg-red-500 text-white"
    : "bg-green-500 text-white";

  return (
    <Link to={href} className="text-white min-w-fit">
      <span className="text-xs md:text-sm text-gray-300">{title}</span>
      <div className="text-base md:text-xl font-bold -mt-2">{price}</div>
      <div className="flex items-center">
        <span className={`${valueClass} text-xs mr-1 px-1 rounded`}>
          %{percentageChange}
        </span>
        <span className={`${percentageClass} text-xs`}>{valueChange}</span>
      </div>
    </Link>
  );
};

export default PriceCard;
